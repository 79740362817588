import React from 'react';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
//import { Router } from '@abyss/web/ui/Router';
import { FooterLinks } from '@hhs/ui/src/common/FooterLinks';
import { styles } from './Content.styles';
import { LandingPage } from './routes/LandingPage';
import { ContactUsCmn } from '../Footer/ContactUs/ContactUsCmn';
import { PrivacyPolicyCmn } from '../Footer/PrivacyPolicy/PrivacyPolicyCmn';
import { TermsOfUseCmn } from '../Footer/TermsOfUse/TermsOfUseCmn';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApmRoutes } from '@elastic/apm-rum-react';

const Content = () => {
  const classes = useStyles(styles)

  return (
    <main id="main-content" className={classes.content}>
      <Router title="{{title}} | COVID-19 Cares Provider Relief Fund">
        <ApmRoutes>
          <Route
            title="Terms Of Use"
            path="/terms-of-use"
            element={<TermsOfUseCmn/>}
          />
          <Route
            title="Privacy Policy"
            path="/privacy-policy"
            element={<PrivacyPolicyCmn/>}
          />
          <Route
            title="Accessibility Statement"
            path="/accessibility-statement"
            element={<FooterLinks.AccessibilityStatement/>}
          />
          <Route
            title="Contact Us"
            path="/contact-us"
            element={<ContactUsCmn/>}
          />
          <Route
            title="Communication Preferences"
            path="/communication-preferences"
            element={<FooterLinks.CommunicationPreferences/>}
          />
          <Route index title="Home" element={<LandingPage/>} />
        </ApmRoutes>
      </Router>
    </main>
  );
};

export { Content };
